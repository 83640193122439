import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { isAlreadyInitialized, store } from './index'
import { nativeBridge } from '@/libs/native-bridge'

import { myDeviceClient } from '@/clients/my-device-client'
import { PostMyDeviceRequest } from '@/dtos/my/device/post'

@Module({ store, dynamic: true, namespaced: true, name: 'myDevice', preserveState: isAlreadyInitialized })
class MyDeviceStore extends VuexModule {
  private _deviceToken: string | null = null

  @Mutation private SET_DEVICE_TOKEN(token: string | null) { this._deviceToken = token }
  @Action setDeviceToken(token: string) { this.SET_DEVICE_TOKEN(token) }

  @Action
  async postMyDevice(): Promise<void> {
    if (!this._deviceToken) return

    const req = new PostMyDeviceRequest(nativeBridge.deviceType, this._deviceToken)
    await myDeviceClient.postDevice(req)

    this.SET_DEVICE_TOKEN(null)
  }
}

export const myDeviceModule = getModule(MyDeviceStore)
