<!--
  Material Iconsのうち、Roundテーマが適用されたアイコンを使用するためのクラス
  呼び出し方は、$[アイコン名]_[テーマ名] （ ex. $check_circle_outline_round ）
  https://materializecss.com/icons.html
-->

<template>
  <i :class="standardClass">{{ parsed.id }}</i>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'

@Component
export default class MaterialIcon extends Vue {
  @Prop({ type: String })
  private name!: string

  get parsed(): { suffix: string, id: string } {
    const themes:string[] = ['round', 'outlined'] // 使用するテーマはここに追加する
    for (const theme of themes) {
      const suffix = theme
      if (this.name.endsWith(suffix)) {
        return {
          suffix: suffix,
          id: this.name.substring(0, this.name.indexOf(suffix) - 1)
        }
      }
    }
    return {
      suffix: '',
      id: this.name
    }
  }

  get standardClass(): string {
    if (this.parsed.suffix) {
      return `material-icons-${this.parsed.suffix}`
    }
    return 'material-icons'
  }
}
</script>
