import { GA_ANONYMOUS, GA_USER_CATEGORY } from '@/constants/constants'
import type { GaUserCategory } from '@/constants/constants'
import { analyticsModule, authModule, myCurrentBuildingModule } from '@/stores'
import { GtmSupport as GtmPlugin } from '@gtm-support/vue-gtm'
import { isNormalLogin, isDummyLogin, isSimulateLogin, isAdmin } from '@/libs/current-user-manager'

export function sendPageViewGtm(gtm: GtmPlugin, path:string, pageName:string):void {
  gtm.trackEvent({
    event: 'page_view',
    user_id: getUserId(),
    page_path: path,
    dimension1: myCurrentBuildingModule.basics.buildingId ?? GA_ANONYMOUS,
    dimension2: getUserId(),
    dimension3: myCurrentBuildingModule.basics.buildingName ?? GA_ANONYMOUS,
    dimension4: authModule.profile.roomNumber ?? GA_ANONYMOUS,
    dimension5: authModule.profile.userName ?? GA_ANONYMOUS,
    dimension6: pageName,
    dimension7: getGAUserCategory(),
    dimension8: analyticsModule.notificationId ?? GA_ANONYMOUS,
    dimension9: analyticsModule.notificationType ?? GA_ANONYMOUS,
  })
}

const getUserId = () : string => {
  if (isNormalLogin()) {
    return authModule.profile.userId ?? analyticsModule.notifiedUserId ?? GA_ANONYMOUS
  } else if (isAdmin()) {
    return authModule.profile.adminId ?? GA_ANONYMOUS
  }
  return GA_ANONYMOUS
}

const getGAUserCategory = () : GaUserCategory|string => {
  if (isNormalLogin()) return GA_USER_CATEGORY.OWNER
  if (isDummyLogin()) return GA_USER_CATEGORY.ADMIN_DUMMY
  if (isSimulateLogin()) return GA_USER_CATEGORY.ADMIN_SIMULATE
  return GA_ANONYMOUS
}
