import { PostMyDeviceRequest } from '@/dtos/my/device/post'
import { APIClientBase } from './api-client-base'

class MyDeviceClient extends APIClientBase {
  constructor() {
    super('/my/device')
  }

  async postDevice(req: PostMyDeviceRequest): Promise<void> {
    await this.post('', req)
  }
}

export const myDeviceClient = new MyDeviceClient()
