import type { RouteLocationNormalized } from 'vue-router'
import { AuthState, AUTH_STATE } from './constants/constants'

export const NAVIGATION_GROUP = {
  INDEPENDENT: 0,
  DECISION_PROCESS: 1,
  MENU: 3,
} as const
export type NavigationGroup = typeof NAVIGATION_GROUP[keyof typeof NAVIGATION_GROUP]

export class RouteMetaData {
  // eslint-disable-next-line
  [key: string|number|symbol]: any
  /** 分類に基づいてナビゲーションのカレント表示が制御される */
  navigationGroup: NavigationGroup = NAVIGATION_GROUP.INDEPENDENT

  specifiedVAppCss = ''

  hideAppBar = false
  hideSystemBar = false
  hideNavigation = false
  removeBottomMargin = false
  showPrintView = false
  keepGlobalError = false

  /** そのページを開くのに必要な認証状態 */
  necessaryAuthState: AuthState = AUTH_STATE.AUTHORIZED

  constructor(partial?: Partial<RouteMetaData>) {
    Object.assign(this, partial)
  }
}

interface IStaticRoute {
  name: string
  path: string
  meta?: RouteMetaData
  alias?: string
  props?: Record<string, unknown> | ((route: RouteLocationNormalized) => unknown)
  children?: Record<string, IStaticRoute> // 未使用
}

export class StaticRoute {
  public readonly name: string
  public readonly path: string
  public readonly meta?: RouteMetaData
  public readonly alias?: string
  public readonly props?: Record<string, unknown> | ((route: RouteLocationNormalized) => unknown)
  public readonly children?: Record<string, IStaticRoute> // 未使用

  constructor(name: string, path: string, meta?: Partial<RouteMetaData>, alias?: string, props?: Record<string, unknown> | ((route: RouteLocationNormalized) => unknown), children?: Record<string, StaticRoute>) {
    this.name = name
    this.path = path
    this.meta = new RouteMetaData(meta)
    this.alias = alias
    this.props = props
    this.children = children // 未使用
  }
}

export const staticRoutes = {
  landing: new StaticRoute('Landing', '',
    { specifiedVAppCss: 'sm__landing-background', necessaryAuthState: AUTH_STATE.ANONYMOUS, hideAppBar: true, hideSystemBar: true, hideNavigation: true, removeBottomMargin: true }, undefined,
    route => {
      // 外部から直接特定のページに遷移したい際に指定される想定（メールやプッシュ通知、素敵ネットとのSSO連携）
      // この画面が必ずルートパスにマッピングされていないといけない
      const { redirect, ...rest } = route.query
      return { redirect, additionalParams: rest }
    }
  ),
  login: new StaticRoute('Login', '/login',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.ANONYMOUS, hideNavigation: true, removeBottomMargin: true }),

  // ※※※※※※※ 以下の4画面は外部連携先から参照されるURLのため変更不可 ※※※※※※※
  loginCompletion: new StaticRoute('LoginCompletion', '/login/completion',
    { necessaryAuthState: AUTH_STATE.ANONYMOUS, hideAppBar: true, hideNavigation: true }, undefined, route => ({ redirectedParams: route.query })),
  externalLogin: new StaticRoute('ExternalLogin', '/external-login',
    { necessaryAuthState: AUTH_STATE.ANONYMOUS, hideAppBar: true, hideNavigation: true }, undefined, route => ({ queryParams: route.query })),
  externalLoginCompletion: new StaticRoute('ExternalLoginCompletion', '/external-login/completion',
    { necessaryAuthState: AUTH_STATE.ANONYMOUS, hideAppBar: true, hideNavigation: true }, undefined, route => ({ redirectedParams: route.query })),
  logoutCompletion: new StaticRoute('LogoutCompletion', '/logout/completion',
    { necessaryAuthState: AUTH_STATE.ANONYMOUS, hideAppBar: true, hideNavigation: true }, undefined, route => ({ redirectedParams: route.query })),
  // ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※

  lmLanding: new StaticRoute('LMLanding', '/landing/lm',
    { specifiedVAppCss: 'sm__landing-background', necessaryAuthState: AUTH_STATE.ANONYMOUS, hideAppBar: true, hideSystemBar: true, hideNavigation: true, removeBottomMargin: true }),
  lmLogin: new StaticRoute('LMLogin', '/login/lm',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.ANONYMOUS, hideNavigation: true, removeBottomMargin: true }),
  lmPassword: new StaticRoute('LMPassword', '/auth/password/lm',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_MFA, hideNavigation: true, removeBottomMargin: true }),
  lmAuthenticationKey: new StaticRoute('LMAuthenticationKey', '/auth/authentication-key/lm',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_MFA, hideNavigation: true, removeBottomMargin: true }),
  lmCaution: new StaticRoute('LMCaution', '/cautions/lm',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_CAUTION, hideNavigation: true, removeBottomMargin: true }),
  selectBuilding: new StaticRoute('SelectBuilding', '/buildings',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_SELECT_BUILDING, hideNavigation: true, removeBottomMargin: true }),

  simulateLoginCompletion: new StaticRoute('SimulateLoginCompletion', '/simulate-login/completion',
    { necessaryAuthState: AUTH_STATE.ANONYMOUS, hideAppBar: true, hideNavigation: true }, undefined, route => ({ queryParams: route.query })),

  identification: new StaticRoute('Identification', '/identification',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_IDENTIFY, hideNavigation: true, removeBottomMargin: true }),
  additionalIdentification: new StaticRoute('AdditionalIdentification', '/identification/additional',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_IDENTIFY, hideNavigation: true, removeBottomMargin: true }),
  onBoarding: new StaticRoute('OnBoarding', '/on-boarding',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_INITIALIZE, hideNavigation: true, removeBottomMargin: true, hideAppBar: true }),
  initialization: new StaticRoute('Initialization', '/initialization',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.HAVE_TO_INITIALIZE, hideNavigation: true, removeBottomMargin: true }),
  contactAnonymous: new StaticRoute('ContactAnonymous', '/contact/anonymous',
    { specifiedVAppCss: 'sm__background-white', necessaryAuthState: AUTH_STATE.ANONYMOUS, hideNavigation: true, removeBottomMargin: true }),

  menu: new StaticRoute('Menu', '/menu', { navigationGroup: NAVIGATION_GROUP.MENU }),
  account: new StaticRoute('Account', '/account', { navigationGroup: NAVIGATION_GROUP.MENU }),

  ownerIdeaDetail: new StaticRoute('OwnerIdeaDetail', '/ideas/1/:ideaId', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),
  adminIdeaDetail: new StaticRoute('AdminIdeaDetail', '/ideas/2/:ideaId', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),
  ideaUpdatesList: new StaticRoute('IdeaUpdatesList', '/ideas/2/:ideaId/updates', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),

  gmResolutionQuestionsList: new StaticRoute('GMResolutionQuestionsList', '/resolutions/1/:resolutionId/questions', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),
  gmResolutionDetail: new StaticRoute('GMResolutionDetail', '/resolutions/1/:resolutionId', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),
  onlineResolutionQuestionsList: new StaticRoute('OnlineResolutionQuestionsList', '/resolutions/2/:resolutionId/questions', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),
  onlineResolutionDetail: new StaticRoute('OnlineResolutionDetail', '/resolutions/2/:resolutionId', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),

  questionnairesList: new StaticRoute('Questionnaires', '/questionnaires', { navigationGroup: NAVIGATION_GROUP.MENU }),
  questionnaireAnswerConfirmation: new StaticRoute('QuestionnairesAnswerConfirmation', '/questionnaires/:questionnaireId/answer', { navigationGroup: NAVIGATION_GROUP.MENU }),
  questionnairesReportDetail: new StaticRoute('QuestionnairesReportDetail', '/questionnaires/:questionnaireId/report', { navigationGroup: NAVIGATION_GROUP.MENU }),

  archivedQuestionnairesList: new StaticRoute('ArchivedQuestionnaires', '/questionnaires/archived', { navigationGroup: NAVIGATION_GROUP.MENU }),
  questionnaireDetailTop: new StaticRoute('QuestionnaireDetailTop', '/questionnaires/:questionnaireId'),

  repairPlan: new StaticRoute('RepairPlan', '/repair-plan', { navigationGroup: NAVIGATION_GROUP.MENU, removeBottomMargin: true }),
  repairPlanUpdates: new StaticRoute('RepairPlanUpdates', '/repair-plan/updates', { navigationGroup: NAVIGATION_GROUP.MENU }),
  repairPlanUpdateDetail: new StaticRoute('RepairPlanUpdateDetail', '/repair-plan/updates/:updateId', { navigationGroup: NAVIGATION_GROUP.MENU }),
  repairPlanPrint: new StaticRoute('RepairPlanPrint', '/repair-plan/print', { showPrintView: true, removeBottomMargin: true }),
  repairPlanDetailPrint: new StaticRoute('RepairPlanDetailPrint', '/repair-plan/detail-print', { showPrintView: true, removeBottomMargin: true }),

  consultations: new StaticRoute('Consultations', '/consultations'),
  notifications: new StaticRoute('Notifications', '/notifications'),
  notificationDetail: new StaticRoute('NotificationDetail', '/notifications/:ownerNotificationId'),

  simpleRepairPlan: new StaticRoute('SimpleRepairPlan', '/simple-repair-plan', { navigationGroup: NAVIGATION_GROUP.MENU, removeBottomMargin: true }),
  simpleRepairPlanUpdates: new StaticRoute('SimpleRepairPlanUpdates', '/simple-repair-plan/updates', { navigationGroup: NAVIGATION_GROUP.MENU, removeBottomMargin: true }),
  simpleRepairPlanDetailPrintPage: new StaticRoute('SimpleRepairPlanDetailPrintPage', '/simple-repair-plan/print', { showPrintView: true, removeBottomMargin: true }),
  simpleRepairPlanConstructionPrint: new StaticRoute('SimpleRepairPlanConstructionPrint', '/simple-repair-plan/construction-print', { showPrintView: true, removeBottomMargin: true }),

  support: new StaticRoute('Support', '/support', { navigationGroup: NAVIGATION_GROUP.MENU }),
  supportQa: new StaticRoute('SupportQa', '/support/qa', { navigationGroup: NAVIGATION_GROUP.MENU }),
  contact: new StaticRoute('Contact', '/contact', { navigationGroup: NAVIGATION_GROUP.MENU }),

  archivedDecisionProcesses: new StaticRoute('ArchivedDecisionProcesses', '/decision-processes/archived', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),
  decisionProcesses: new StaticRoute('DecisionProcesses', '/decision-processes', { navigationGroup: NAVIGATION_GROUP.DECISION_PROCESS }),

  maintenance: new StaticRoute('Maintenance', '/maintenance', { necessaryAuthState: AUTH_STATE.ANONYMOUS, hideNavigation: true, removeBottomMargin: true }),

  initialRegistrationManual: new StaticRoute('staticAssets', '/static-assets/initial-registration-manual', { necessaryAuthState: AUTH_STATE.ANONYMOUS, hideNavigation: true }),

  deleted: new StaticRoute('Deleted', '/deleted/:content', { keepGlobalError: true }),
  notFound: new StaticRoute('NotFound', '/:pathMatch(.*)*', { keepGlobalError: true }),
} as const

export const staticRoutesLocal = {
  helloWorld: new StaticRoute('HelloWorld', '/hello-world', { necessaryAuthState: AUTH_STATE.ANONYMOUS }),
} as const
