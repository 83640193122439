import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  class: "pa-4 sm__background-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sm_system_bar = _resolveComponent("sm-system-bar")!
  const _component_sm_drawer = _resolveComponent("sm-drawer")!
  const _component_sm_app_bar = _resolveComponent("sm-app-bar")!
  const _component_sm_progress_overlay = _resolveComponent("sm-progress-overlay")!
  const _component_sm_text = _resolveComponent("sm-text")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_sm_dialog = _resolveComponent("sm-dialog")!
  const _component_sm_bottom_navigation = _resolveComponent("sm-bottom-navigation")!
  const _component_sm_snackbar = _resolveComponent("sm-snackbar")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app, {
      class: _normalizeClass(_ctx.specifiedVAppCss)
    }, {
      default: _withCtx(() => [
        (!this.$vuetify.display.mobile && _ctx.systemBarVisible)
          ? (_openBlock(), _createBlock(_component_sm_system_bar, {
              key: 0,
              "first-btn": _ctx.systemBarFirstBtn,
              "second-btn": _ctx.drawerVisible ? { icon: '$notifications_outlined', text: 'お知らせ' } : undefined,
              "second-badge-visible": _ctx.notificationBadgeVisible,
              onClickFirstBtn: _ctx.goToConsultations,
              onClickSecondBtn: _ctx.goToNotifications,
              class: _normalizeClass({'d-print-none' : _ctx.isPrintView})
            }, null, 8, ["first-btn", "second-btn", "second-badge-visible", "onClickFirstBtn", "onClickSecondBtn", "class"]))
          : _createCommentVNode("", true),
        (_ctx.drawerVisible)
          ? (_openBlock(), _createBlock(_component_sm_drawer, {
              key: 1,
              items: _ctx.navigations,
              current: _ctx.currentNavigation,
              onClickItem: _ctx.transTo,
              class: _normalizeClass({'d-print-none' : _ctx.isPrintView})
            }, null, 8, ["items", "current", "onClickItem", "class"]))
          : _createCommentVNode("", true),
        (_ctx.appBar.visible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass({'d-print-none' : _ctx.isPrintView})
            }, [
              (_ctx.drawerVisible)
                ? (_openBlock(), _createBlock(_component_sm_app_bar, {
                    key: 0,
                    app: "",
                    modelValue: _ctx.selectedTab,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
                    label: _ctx.appBar.title,
                    "left-icon": _ctx.appBar.leftIcon,
                    onClickLeftIcon: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back())),
                    "is-extension-visible": _ctx.appBar.isExtensionVisible,
                    onClickExtensionBtn: _ctx.onClickExtensionBtn,
                    class: _normalizeClass({'d-print-none':_ctx.isPrintView})
                  }, null, 8, ["modelValue", "label", "left-icon", "is-extension-visible", "onClickExtensionBtn", "class"]))
                : (_openBlock(), _createBlock(_component_sm_app_bar, {
                    key: 1,
                    app: "",
                    modelValue: _ctx.selectedTab,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedTab) = $event)),
                    label: _ctx.appBar.title,
                    "left-icon": _ctx.appBar.leftIcon,
                    onClickLeftIcon: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.back())),
                    "icon-visible": _ctx.appBar.rightIconVisible,
                    "right-first-icon": !_ctx.isConsultation ? undefined : '$sm_chat',
                    "right-second-icon": "$notifications_outlined",
                    "right-second-badge-visible": _ctx.notificationBadgeVisible,
                    onClickRightFirstIcon: _ctx.goToConsultations,
                    onClickRightSecondIcon: _ctx.goToNotifications,
                    "is-extension-visible": _ctx.appBar.isExtensionVisible,
                    onClickExtensionBtn: _ctx.onClickExtensionBtn,
                    class: _normalizeClass({'d-print-none':_ctx.isPrintView})
                  }, null, 8, ["modelValue", "label", "left-icon", "icon-visible", "right-first-icon", "right-second-badge-visible", "onClickRightFirstIcon", "onClickRightSecondIcon", "is-extension-visible", "onClickExtensionBtn", "class"]))
            ], 2))
          : _createCommentVNode("", true),
        _createVNode(_component_v_main, {
          class: _normalizeClass([
          _ctx.smMainClassDecider,
          _ctx.applyBottomMargin ? 'sm__main-bottom-margin' : '',
        ])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_sm_progress_overlay, { "show-overlay": _ctx.showOverlay }, null, 8, ["show-overlay"]),
            (_ctx.globalErrors.length > 0 && _ctx.globalErrorVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_sm_text, {
                    text: _ctx.globalErrors.join(''),
                    color: "caution"
                  }, null, 8, ["text"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_router_view)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_sm_dialog, {
          "model-value": _ctx.showReLoginDialog,
          "title-text": "再ログインが必要です",
          "body-text": "一定時間が経過したため、自動でログアウトされました。",
          "first-btn-label": "ログイン画面へ",
          onClickFirstBtn: _ctx.forceRelogin
        }, null, 8, ["model-value", "onClickFirstBtn"]),
        _createVNode(_component_sm_dialog, {
          "model-value": _ctx.showSimulateReLoginDialog,
          "title-text": "再ログインが必要です",
          "body-text": '一定時間が経過したため、自動でログアウトされました。\n画面を閉じて、管理者アプリから再度「利用中画面を見る」操作を行ってください。'
        }, null, 8, ["model-value"]),
        (_ctx.bottomNavigationVisible)
          ? (_openBlock(), _createBlock(_component_sm_bottom_navigation, {
              key: 3,
              items: _ctx.navigations,
              current: _ctx.currentNavigation,
              onClickItem: _ctx.transTo,
              class: _normalizeClass({'d-print-none':_ctx.isPrintView})
            }, null, 8, ["items", "current", "onClickItem", "class"]))
          : _createCommentVNode("", true),
        (_ctx.displaySnackbar)
          ? (_openBlock(), _createBlock(_component_sm_snackbar, {
              key: 4,
              modelValue: _ctx.displaySnackbar,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displaySnackbar) = $event)),
              message: _ctx.snackbar.message,
              caution: _ctx.snackbar.caution
            }, null, 8, ["modelValue", "message", "caution"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}