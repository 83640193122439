import { Type } from 'class-transformer'
import { AuthState } from '@/constants/constants'
import { LoginResultCode, OwnerProfileDto } from '@/dtos/auth/common'

export class LoginPostRequest {
  emailAddress: string
  password: string

  constructor(email: string, pass: string) { this.emailAddress = email; this.password = pass }
}

export class LoginPostResponse {
  resultCode!: LoginResultCode
  authState?: AuthState
  @Type(() => OwnerProfileDto) profile?: OwnerProfileDto
  failedMessage?: string

  redirectTo?: string
  accessToken?: string
}
