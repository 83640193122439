import { APIClientBase } from './api-client-base'
import { AuthAdminPostRequest, AuthAdminPostResponse } from '@//dtos/auth/admin/post'
import { IdentificationPostRequest, IdentificationPostResponse } from '@/dtos/auth/identification/post'
import { AdditionalIdentificationPostRequest, AdditionalIdentificationPostResponse } from '@/dtos/auth/identification/additional/post'
import { LoginPostRequest, LoginPostResponse } from '@/dtos/auth/login/post'
import { LogoutPostResponse } from '@/dtos/auth/logout/post'
import { SimulateLoginPostRequest, SimulateLoginPostResponse } from '@/dtos/auth/simulate/post'
import { SsoVerifyPostRequest, SsoVerifyPostResponse } from '@/dtos/auth/sso-verify/post'
import { SsoVerifyGetResponse } from '@/dtos/auth/sso-verify/get'

class AuthClient extends APIClientBase {
  constructor() {
    super('/auth/')
  }

  postLogin(req: LoginPostRequest): Promise<LoginPostResponse | undefined> {
    return this.post('login', req, LoginPostResponse)
  }

  postLogout() {
    return this.post('logout', undefined, LogoutPostResponse)
  }

  postIdentification(req: IdentificationPostRequest): Promise<IdentificationPostResponse | undefined> {
    return this.post('identification', req, IdentificationPostResponse)
  }

  postAdditionalIdentification(req: AdditionalIdentificationPostRequest): Promise<AdditionalIdentificationPostResponse | undefined> {
    return this.post('identification/additional', req, AdditionalIdentificationPostResponse)
  }

  getSsoVerify(): Promise<SsoVerifyGetResponse | undefined> {
    return this.get('sso-verify', undefined, SsoVerifyGetResponse)
  }

  postSsoVerify(req: SsoVerifyPostRequest): Promise<SsoVerifyPostResponse | undefined> {
    return this.post('sso-verify', req, SsoVerifyPostResponse)
  }

  postAdminLogin(req: AuthAdminPostRequest): Promise<AuthAdminPostResponse | undefined> {
    return this.post('admin', req, AuthAdminPostResponse)
  }

  postSimulateLogin(req: SimulateLoginPostRequest): Promise<SimulateLoginPostResponse | undefined> {
    return this.post('simulate', req, SimulateLoginPostResponse)
  }
}

export const authClient = new AuthClient()
