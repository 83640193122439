<template>
  <svg
    class="sm__svg"
    height="24px"
    width="24px"
  >
    <path
      d="M11 17H13V19H11V17ZM11 9H13V15H11V9Z"
    />
    <path
      d="M22.4 21.7H1.60005C1.20005 21.7 0.900049 21.5 0.700049 21.2C0.500049 20.9 0.500049 20.5 0.700049 20.2L11.1 2.2C11.5 1.6 12.5 1.6 12.8 2.2L23.2001 20.1C23.4001 20.4 23.4001 20.8 23.2001 21.1C23.1 21.5 22.7 21.7 22.4 21.7ZM3.30005 19.7H20.6L12 4.7L3.30005 19.7Z"
    />
  </svg>
</template>

<style scoped>
.sm__svg {
  fill:currentColor;
}
</style>
